<template>
  <v-form ref="form">
    <card-view
        v-if="dataState.isModalOpen"
        :title="(dataState.modalData===null ? '' : typeof dataState.modalData.id=='undefined' ? 'Создать план работы' : 'Изменить план работы #'+dataState.modalData.id)"
        big
        width="440px" cardClass="position-fixed overflow-auto border-left" style="right:0;bottom:0;top:64px;z-index: 999;"
        rounded="false"
        elevation="0"
        contentFullSize
    >
      <template v-slot:content v-if="dataState.modalData!==null">
        <div class="px-3">
        <log v-if="typeof dataState.modalData.id!=='undefined'" entity-name="UserSpecPlan" :entity-id="dataState.modalData.id" logClass="mx-4 mb-4"/>
  
        <autocomplete-single name="Специалист" :items="users" :select.sync="userSelected" required>
            <template v-slot:customInfo="{item}">
              <v-row no-gutters class="py-2" style="max-width: 400px;">
                <v-col cols="12" class="subtitle-2">
                  <v-list-item-avatar class="user mr-3" size="32">
                    <img :src="item.imageUrl" alt="" v-if="item.imageUrl">
                  </v-list-item-avatar>
                  {{$tools.userFullName(item)}}
                </v-col>
                <v-col cols="12">
                  <template v-for="(userSpec,index) in item.userSpecs">
                  <div class="caption" v-if="!$tools.isEmpty(userSpec.commentAdmin)" :key="'us_'+index">
                    <div>
                      <v-avatar :color="userSpec.comDiv.comBranch.color" size="10" class="mr-1" style="margin-top:-2px"/>
                      {{userSpec.spec.name}} ({{ userSpec.comDiv.comBranch.name }})
                    </div>
                      <v-icon class="ml-2 mr-1" small>mdi-comment-text-outline</v-icon>
                      {{userSpec.commentAdmin}}
                  </div>
                </template>
                </v-col>
              </v-row>
            </template>
            <template v-slot:selectedInfo="{item}">
              <v-list-item-avatar class="user mr-3" size="32">
                <img :src="item.imageUrl" alt="" v-if="item.imageUrl">
              </v-list-item-avatar>
              {{$tools.userFullName(item)}} 
              <v-btn 
                  @click.stop
                  x-small
                  class="ml-3"
                  icon depressed :href="`/user/spec/${item.id}/`" target="_blank">
                <v-icon small>mdi-open-in-new</v-icon>
              </v-btn>
            </template>     
        </autocomplete-single>
   
        <autocomplete-single name="Специализация" :items="userSpecs" :select.sync="userSpecSelected" required>
            <template v-slot:itemIcon="{item}">
              <v-avatar :color="item.comDiv.comBranch.color" size="10" class="mr-1" style="margin-top:-2px"/>
              {{item.spec.name}}  ({{item.comDiv.comBranch.name}})
            </template>
            <template v-slot:selectedInfo="{item}">
              <v-avatar :color="item.comDiv.comBranch.color" size="10" class="mr-1" style="margin-top:-2px"/>
              {{item.spec.name}}  ({{item.comDiv.comBranch.name}})
            </template>
        </autocomplete-single>

        <text-area-field name="Комментарий о специалисте" :value.sync="commentAdmin" autoGrowDisabled :rows="2"/>

        <v-divider class="my-5"/>

        <autocomplete-single name="Кабинет" :items="comRoomsWithComPlaces" :select.sync="comRoomSelected" required>
            <template v-slot:customInfo="{item}">
              <v-row no-gutters class="py-2" style="max-width: 400px;">
                <v-col cols="12" class="subtitle-2">
                  <v-avatar :color="item.comFloor.comBuilding.comBranch.color" size="10" class="mr-1" style="margin-top:-2px"/>
                  <span class="font-weight-bold">{{item.sid}}</span> {{item.name}} ({{item.comFloor.comBuilding.comBranch.name}})
                </v-col>
                <v-col cols="12" class="caption">
                  <span class="font-weight-bold">Рабочие места:</span>
                  <template v-for="(comPlace,index) in item.comPlaces">
                    <span class="caption" v-if="!$tools.isEmpty(comPlace.name)" :key="'cp_'+index">
                        {{comPlace.name}}<span v-if="item.comPlaces.length-1!==index">,</span>
                    </span>
                  </template>  
                </v-col>
              </v-row>
            </template>
            <template v-slot:selectedInfo="{item}">
              <v-avatar :color="item.comFloor.comBuilding.comBranch.color" size="10" class="mr-1" style="margin-top:-2px"/>
              {{item.name}} ({{item.comFloor.comBuilding.comBranch.name}})
            </template>
        </autocomplete-single>

        <autocomplete-single name="Рабочее место" :items="comRoomSelected.comPlaces" :select.sync="comPlaceSelected" required/>

        <v-divider class="my-5"/>

        <date :value.sync="beginDateState.modalData" name="Дата"/>
     
        <time-range :begin.sync="beginTime" :end.sync="endTime" :dayTimeBegin="dayTimeBegin" :dayTimeEnd="dayTimeEnd"/>

        <v-row class="mt-2">
          <v-col :cols="dataState.modalData.type==='workVisit' || dataState.modalData.type==='workVisitReserve' ? 6 : 12">
            <v-select v-model="dataState.modalData.type" label="Тип плана" :items="types" outlined dense hide-details="auto" item-text="name"/>
          </v-col>
          <v-col v-if="dataState.modalData.type==='workVisit' || dataState.modalData.type==='workVisitReserve'" cols="6">
            <v-select v-model="dataState.modalData.gridMinutes" label="Интервал визитов" :items="gridMinutesList" outlined dense hide-details="auto" item-text="name"/>
          </v-col>
        </v-row>
        <v-divider class="my-5"/> 
        </div>
      </template>
      <template v-slot:actions>
        <v-row>
          <v-col cols="4"><v-btn  @click="dataState.modalClose();$emit('cancel')" block small depressed>Отмена <v-icon class="ml-2" small>mdi-cancel</v-icon></v-btn></v-col>
          <v-col cols="4"><v-btn @click="divideState.modalOpen({time:null})" block small depressed>Разделить <v-icon class="ml-2" small>mdi-scissors-cutting</v-icon></v-btn></v-col>
          <v-col cols="4"><v-btn color="error" @click="deleteState.modalOpen()" block small depressed>Удалить <v-icon class="ml-2" small>mdi-delete</v-icon></v-btn></v-col>          
          <v-col cols="12"><v-btn color="primary"  @click="createOrUpdate()" block small depressed :loading="updateState.isLoading">Сохранить</v-btn></v-col>
        </v-row>
        <dialog-view
          :open="divideState.isModalOpen"
          :title="'Разделить план работы #'+dataState.modalData.id+'?'"
          @cancel="divideState.modalClose()"
        >
          <template v-slot:content>
            <v-form v-if="divideState.modalData!==null" ref="divideForm">
              <v-text-field
                  v-model="divideState.modalData.time"
                  label="Время"
                  v-mask="'##:##'"
                  :rules="formFieldRules.required"
                  outlined dense hide-details="auto" class="mb-4" clearable
              />
            </v-form>
          </template>
          <template v-slot:actions>
            <v-btn color="primary" text :loading="divideState.isLoading" @click="divide()">Разделить<v-icon class="ml-2" small>mdi-scissors-cutting</v-icon></v-btn>
          </template>
        </dialog-view>
        <dialog-view
          :open="deleteState.isModalOpen"
          :title="'Удалить план работы #'+dataState.modalData.id+'?'"
          @cancel="deleteState.modalClose()"
        >
          <template v-slot:actions>
            <v-btn color="primary" text :loading="deleteState.isLoading" @click="del()">Удалить</v-btn>
          </template>
        </dialog-view>
      </template>
    </card-view>
  </v-form>
</template>

<script>
import State from "@/plugins/state";
import Log from "@/views/Log/Log";
import DialogView from "@/components/DialogView";
import moment from "moment";
import {FormFieldRules} from "@/plugins/formFieldRules";
import axios from "axios";
import Api from "@/Api";
import CardView from "@/components/CardView";
import TimeRange from "@/components/TimeRange";

import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
import TextAreaField from "@/componentsV2/base/TextAreaField.vue";
import Date from "@/componentsV2/base/Date.vue";

import {
  Entity_UserSpec, 
  Entity_User, 
  Entity_Spec, 
  Entity_ComBranch,
  Entity_ComDiv,
} from "../../../../EntityStoreCacheService";

export default {
  components: {
    AutocompleteSingle,
    TextAreaField,
    Date,
    CardView, 
    DialogView, 
    Log,
    TimeRange
  },
  props: ['state','comRooms'],
  data: () => ({
    loadState: new State(),
    dataState: new State(),
    updateState: new State(),
    divideState: new State(),
    deleteState: new State(),

    
    userSelected:[],

    commentAdmin:null,
    userSpecs:[],
    userSpecSelected:{},
    formFieldRules: FormFieldRules,
    comRoomsWithComPlaces: [],
    comRoomSelected: {},
    comPlaceSelected: {},

    types:[
      {name:'Прием (Самозапись вкл)',value:'workVisit'},
      {name:'Прием (Самозапись откл)',value:'workVisitReserve'},
      {name:'Прием отключен',value:'work'},
      {name:'Перерыв, обед',value:'eat'},
      {name:'Отпуск, больничный',value:'break'},
    ],
    gridMinutesList:[
      {name:'5 минут',value:5},
      {name:'10 минут',value:10},
      {name:'15 минут',value:15},
      {name:'20 минут',value:20},
      {name:'30 минут',value:30},
      {name:'45 минут',value:45},
      {name:'60 минут',value:60}
    ],

    beginDateState:new State(),
    beginTime:null,
    endTime:null,

    dayTimeBegin:null,
    dayTimeEnd:null,
    blockWatch: false
  }),
  mounted(){
    this.comRoomsWithComPlaces = this.comRooms.filter(room => room.comPlaces.length>0)
    this.load();
  },
  watch: {
    comRooms(){
      this.comRoomsWithComPlaces = this.comRooms.filter(room => room.comPlaces.length>0)
    },
    state: {
      deep: true,
      handler() {
        if(this.state.isModalOpen){

          this.userSelected = {};
          this.comPlaceSelected = {};

          this.dataState.modalOpen(JSON.parse(JSON.stringify(this.state.modalData)));
          this.$refs.form.resetValidation();

          this.beginDateState.modalData = this.$tools.dateTimeFormat(this.dataState.modalData.begin, 'DD.MM.YYYY');
          this.beginDateState.modalData1 = this.$tools.dateTimeFormat(this.dataState.modalData.begin, 'YYYY-MM-DD');

          this.beginTime = moment(this.dataState.modalData.begin, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
          this.endTime = moment(this.dataState.modalData.end, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');

          this.comRoomSelected = this.dataState.modalData.comPlace.comRoom;
          this.comPlaceSelected = this.dataState.modalData.comPlace;

          if(typeof this.state.modalData.id!=='undefined'){    
            this.blockWatch = true;      
            this.userSelected = this.dataState.modalData.userSpec.user;
            this.userSpecs = this.users.filter(v=>v.id===this.userSelected.id)[0].userSpecs;
            this.userSpecSelected = this.dataState.modalData.userSpec;
            
            this.$nextTick(() => {
              this.blockWatch = false;
            });  
          }

          //dayTimeEnd
          if(this.dataState.modalData.dayTimeBegin&&this.dataState.modalData.dayTimeEnd){
            this.dayTimeBegin = this.dataState.modalData.dayTimeBegin
            this.dayTimeEnd = this.dataState.modalData.dayTimeEnd
          }

        }
      },
    },
    userSelected() {
      if (!this.blockWatch) {
        this.userSpecs = [];
        this.commentAdmin = null;
        this.userSpecSelected = {};
        
        if(Object.keys(this.userSelected).length!==0){
          this.userSpecs = this.users.filter(v=>v.id===this.userSelected.id)[0].userSpecs;
          let spec = this.userSpecs.filter(item=>item.comDiv.comBranch.id===this.comRoomSelected.comFloor.comBuilding.comBranch.id)
          if(spec.length>0)
          this.userSpecSelected = spec[0];
        }
      }
    },
    userSpecSelected() {
      this.commentAdmin = null;
      if(Object.keys(this.userSpecSelected).length!==0)
        this.commentAdmin = this.userSpecSelected.commentAdmin
    },
    comRoomSelected() {
      if (this.comRoomSelected.comPlaces.filter(cp=>cp.id===this.comPlaceSelected.id).length === 0)
        this.comPlaceSelected = this.comRoomSelected.comPlaces[0]
    }
  },
  methods: {
    load(){
      this.loadState.stateLoading();
      
        let rb = new Entity_User()
        .selects([Entity_User.id].join('.'))
        .selects([Entity_User.firstName].join('.'))
        .selects([Entity_User.lastName].join('.'))
        .selects([Entity_User.secondName].join('.'))
        .selects([Entity_User.imageUrl].join('.'))
        .selects([Entity_User.type].join('.'))

        // .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.user, Entity_User.userSpecs, Entity_UserSpec.id ].join('.'))
        // .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.user, Entity_User.userSpecs, Entity_UserSpec.commentAdmin].join('.'))
        
        .selects([Entity_User.userSpecs, Entity_UserSpec.id ].join('.'))
        .selects([Entity_User.userSpecs, Entity_UserSpec.spec, Entity_Spec.id ].join('.'))
        .selects([Entity_User.userSpecs, Entity_UserSpec.spec, Entity_Spec.name ].join('.'))
        .selects([Entity_User.userSpecs, Entity_UserSpec.spec, Entity_Spec.color ].join('.'))

        .selects([Entity_User.userSpecs, Entity_UserSpec.commentAdmin ].join('.'))
        .selects([Entity_User.userSpecs, Entity_UserSpec.comDiv, Entity_ComDiv.comBranch,Entity_ComBranch.id ].join('.'))
        .selects([Entity_User.userSpecs, Entity_UserSpec.comDiv, Entity_ComDiv.comBranch,Entity_ComBranch.name ].join('.'))
        .selects([Entity_User.userSpecs, Entity_UserSpec.comDiv, Entity_ComDiv.comBranch,Entity_ComBranch.color ].join('.'))
        rb.filterAnd([Entity_User.entity, Entity_User.type].join('.')+' !=?','client');

        rb.page(1).onPage(10000);


        rb.request((data)=>{
          this.users = data.items
              
          this.loadState.stateSuccess();
        }, (e)=>{
          console.error(e.response.data.message);
          this.loadState.stateError();
        })
    },
    divide() {
      if (this.$refs.divideForm.validate()) {
        this.divideState.stateLoading();
        axios.put(Api.host+'/userSpecPlan/'+this.dataState.modalData.id+'/divide/', this.divideState.modalData)
            .then(()=> {
              this.divideState.stateSuccess();
              this.divideState.modalClose();
              this.$store.commit('alertSuccess', 'План работы разделен');
              this.$emit('createdUpdatedDeleted');
              this.dataState.modalClose();
            })
            .catch((e)=> {
              this.$store.commit('alertError', e.response.data.message);
              this.divideState.stateError();
              this.divideState.modalClose();
              console.log(e)
            });
      }
    },
    del() {
      this.deleteState.stateLoading();
      axios.delete(Api.host + '/userSpecPlan/' + this.dataState.modalData.id + '/')
          .then(() => {
            this.deleteState.stateSuccess();
            this.deleteState.modalClose();
            this.$store.commit('alertSuccess', 'План работы удален');
            this.$emit('createdUpdatedDeleted');
            this.dataState.modalClose();
          })
          .catch((e) => {
            this.$store.commit('alertError', e.response.data.message);
            this.deleteState.stateError();
            this.deleteState.modalClose();
            console.log(e)
          });
    },
    createOrUpdate(){
      const isFormValid = this.$refs.form.validate();

      let fieldsValid = true;
      let arr = [
        this.userSelected,
        this.userSpecSelected,
        this.comRoomSelected,
        this.comPlaceSelected
      ];

      for (let value of arr) {
        if (Object.keys(value).length === 0) {
          fieldsValid = false
          break 
        }  
      }

      if (isFormValid && fieldsValid) {
        this.updateState.stateLoading();
        if(typeof this.dataState.modalData.id==='undefined'){
          axios.post(Api.host+'/userSpecPlan/', {
            comPlace:this.comPlaceSelected.id,
            userSpec:this.userSpecSelected.id,
            begin:moment(this.beginDateState.modalData+' '+this.beginTime+':00', 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            end:moment(this.beginDateState.modalData+' '+this.endTime+':00', 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            type:this.dataState.modalData.type,
            gridMinutes:this.dataState.modalData.gridMinutes,
            userSpecCommentAdmin:this.commentAdmin
          })
              .then(()=> {
                this.updateState.stateSuccess();
                this.$store.commit('alertSuccess', 'План работы создан');
                this.$emit('createdUpdatedDeleted');
                this.dataState.modalClose();
              })
              .catch((e)=> {
                this.updateState.stateError();
                this.$store.commit('alertError', e.response.data.message);
              });
        } else {
          axios.put(Api.host+'/userSpecPlan/'+this.dataState.modalData.id+'/', {
            comPlace:this.comPlaceSelected.id,
            userSpec:this.userSpecSelected.id,
            begin:moment(this.beginDateState.modalData+' '+this.beginTime+':00', 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            end:moment(this.beginDateState.modalData+' '+this.endTime+':00', 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            type:this.dataState.modalData.type,
            gridMinutes:this.dataState.modalData.gridMinutes,
            userSpecCommentAdmin:this.commentAdmin
          })
            .then(()=> {
              this.updateState.stateSuccess();
              this.$store.commit('alertSuccess', 'План работы обновлен');
              this.$emit('createdUpdatedDeleted');
              this.dataState.modalClose();
            })
            .catch((e)=> {
              this.$store.commit('alertError', e.response.data.message);
              this.updateState.stateError();
              console.log(e)
            });
        }
      } 
    },
  }
}
</script>


